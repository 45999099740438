<template>
  <div class="row">
    <div class="col-12 col-lg-6 g c">
      <div class="card card-body">
        اختيار الطالب (لمحضر الغياب وتعهد التأخر عن الاختبار):
        <v-select :options="students" v-model="selectedStudent" label="name">
        </v-select>
      </div>
    </div>
    <div class="col-12"></div>
    <div
      class="col-12 col-lg-4"
      title="اضغط للتحرير والطباعة"
      v-for="(item, index) in items"
      :key="index"
      @click="openMe(item.id)"
    >
      <div class="card kashf">
        <div class="card-body">
          <img :src="require('@/assets/images/eye.png')" class="print" />
          <img
            :src="require(`@/assets/images/kushof/${item.id}.png`)"
            style="
              width: 100%;
              object-fit: cover;
              height: 180px;
              filter: blur(2px);
            "
          />
        </div>
        <div class="card-footer text-center">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div
      class="col-12 col-lg-4"
      title="اضغط للتحرير والطباعة"
      v-b-modal.modal-1
    >
      <div class="card kashf">
        <div class="card-body">
          <img :src="require('@/assets/images/eye.png')" class="print" />
          <img
            :src="require(`@/assets/images/kushof/5.png`)"
            style="
              width: 100%;
              object-fit: cover;
              height: 180px;
              filter: blur(2px);
            "
          />
        </div>
        <div class="card-footer text-center">
          كشف رصد درجات مادة
        </div>
      </div>
    </div>
    <div
      class="col-12 col-lg-4"
      title="اضغط للتحرير والطباعة"
      v-b-modal.modal-2
    >
      <div class="card kashf">
        <div class="card-body">
          <img :src="require('@/assets/images/eye.png')" class="print" />
          <img
            :src="require(`@/assets/images/kushof/5.png`)"
            style="
              width: 100%;
              object-fit: cover;
              height: 180px;
              filter: blur(2px);
            "
          />
        </div>
        <div class="card-footer text-center">
          كشف رصد اختبار نهاية الفصل
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title=" "
      size="sm"
      hide-footer
    >
    <div class="form-group">
      <h5 for="">اسم المادة</h5>
      <input type="text"
        class="form-control form-control-lg" v-model="subject" placeholder="اكتب اسم المادة هنا... (مثل: الاحياء)">
    </div>
    <div class="form-group">
      <h5 for="">اختر الفصل</h5>
      <select class="form-control" v-model="classroom">
        <option value="">-- اختر --</option>
        <option v-for="c in classrooms" :key="c">{{ c }}</option>
      </select>
    </div>
    <div class="form-group">
      <h5 for="">اسم الفصل</h5>
      <input type="text"
        class="form-control form-control-lg" v-model="classroom_name" placeholder="اكتب اسم الفصل هنا... (مثل: اول ٢)">
    </div>
    <div class="col-12 text-center g">
      <button class="btn btn-primary" @click="openMe(8)">طباعة الكشف الان</button>
    </div>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="modal-2"
      title=" "
      size="sm"
      hide-footer
    >
    <div class="form-group">
      <h5 for="">اسم المادة</h5>
      <input type="text"
        class="form-control form-control-lg" v-model="subject" placeholder="اكتب اسم المادة هنا... (مثل: الاحياء)">
    </div>
    <div class="form-group">
      <h5 for="">اختر الفصل</h5>
      <select class="form-control" v-model="classroom">
        <option value="">-- اختر --</option>
        <option v-for="c in classrooms" :key="c">{{ c }}</option>
      </select>
    </div>
    <div class="form-group">
      <h5 for="">اسم الفصل</h5>
      <input type="text"
        class="form-control form-control-lg" v-model="classroom_name" placeholder="اكتب اسم الفصل هنا... (مثل: اول ٢)">
    </div>
    <div class="col-12 text-center g">
      <button class="btn btn-primary" @click="openMe(9)">طباعة الكشف الان</button>
    </div>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { 
  BFormGroup, 
  BModal,
  VBModal,
  } from "bootstrap-vue";

import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    BFormGroup,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  created() {
    var g = this;
    $.post(api + "/committees/students", {
      jwt: user.jwt,
    })
      .then(function (r) {
        if (r.status == 100) {
          g.students = r.response;
          g.students.forEach(function(a){
            if(!g.classrooms.includes(a.classname + ' - ' + a.classroom)){
              g.classrooms.push(a.classname + ' - ' + a.classroom)
            }
          })
        } else {
          alert("حدث خطأ2");
        }
      })
      .catch(function () {
        alert("حدث خطأ");
      });
  },
  data() {
    return {
      teachers: [],
      subject: "",
      classroom: "",
      classroom_name: "",
      name: "",
      number: "",
      students: [],
      selectedStudent: { name: "", number: "" },
      items: [
        {
          id: 1,
          title: "خطة الاختبـارات",
        },
        {
          id: 2,
          title: "محضر غياب طالب عن الاختبار",
        },
        {
          id: 3,
          title: "أعداد الطلاب في اللجان",
        },
        {
          id: 4,
          title: "متابعة سير أوراق الإجابة",
        },
        {
          id: 5,
          title: "كشف بأسماء الملاحظين اليومي",
        },
        {
          id: 6,
          title: "محضر فتح مظروف أسئلة",
        },
        {
          id: 7,
          title: "تعهد طالب تأخر عن الاختبار بما لا يتجاوز 15 دقيقة",
        }
      ],
      classrooms: []
    };
  },
  methods: {
    openMe(id) {
      window.open(
        `/kushof/${id}?name=${encodeURI(this.selectedStudent.name)}&number=${
          this.selectedStudent.number
        }&classroom=${encodeURI(this.classroom)}&classroom_name=${encodeURI(this.classroom_name)}&subject=${encodeURI(this.subject)}`
      );
    },
  },
};
</script>

<style>
@import url(https://cdnjs.cloudflare.com/ajax/libs/vue-select/3.10.0/vue-select.css);
.kashf:hover {
  cursor: pointer;
  transition: transform 0.2s;
  transform: scale(1.1);
}
.kashf .print {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50px;
}
.kashf .card-body {
  position: relative;
}
</style>